export const FilmItems = [
  {
    title: "CLAW",
    slug: "claw",
    role: "Charlie",
    year: "2022",
    previewText:
      "In production - Boxing short movie about Jack who loses his gym and his Son Charlie having his first real fight.",
    markdown: "content/2022/claw.md",
    showMore: false,
  },
];
